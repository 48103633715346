import { notify } from 'front-commons/ds';
import { CounterLoadingTypes } from 'front-commons/ds/components/Counter/interfaces';
import { useDebounce } from 'front-commons/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { initializeBasket, manageBasketProductAmount, validateBasketProductAmount } from 'services/basket';
import { SuggestedProps } from 'services/basket/interfaces';
import { CustomError } from 'shared/errors';
import useBasket from 'stores/basket';
import useDialog from 'stores/dialog';
import usePos from 'stores/pos';
import AddToBasketButtonRender from './AddToBasketButtonRender';
import { getManageProductMessage, getNotificationMessage, getProductInfo, manageAmountByMethod } from './helpers';
import { AddBasketItemsParams, AddToBasketButtonProps, HandleChangeParams } from './interfaces';

export default function AddToBasketButton({
	buId,
	width,
	label,
	posId,
	disabled,
	allowZero = false,
	productId,
	allowEmpty = false,
	outOfContext = false,
	hasPermission,
	distributorId,
	counterOptions,
	productsInCombo,
	initialQuantity,
	disableAfterAdd = false,
	watchInitialValue,
	suggestionGroupId,
	suggestionGroupName,
	maxQuantity,
	onItemChange,
	onUpdateBasketType,
}: AddToBasketButtonProps & Partial<SuggestedProps>) {
	const [quantity, setQuantity] = useState<AddBasketItemsParams>({
		amount: initialQuantity || 0,
		method: undefined,
	});
	const [forcedUpdate, setForcedUpdate] = useState(false);
	const [loading, setLoading] = useState<CounterLoadingTypes>(false);
	const [loadingValidate, setLoadingValidate] = useState(false);
	const [contextQuantity, setContextQuantity] = useState(initialQuantity || 0);

	const { handleOpenDialog } = useDialog();

	const {
		handleGetBasketData,
		handleBasketLoading,
		handleRemoveFromBasket,

		basketStore: { basket: basketData, loading: loadingBasket },
	} = useBasket();

	const debouncedQuantity = useDebounce(quantity, 1000);
	const { posStore } = usePos();
	const pos = posStore.selectedPos;

	const productInBasket = useMemo(() => {
		if (!productId || !distributorId || !buId) return undefined;

		const newData = getProductInfo({ productId, distributorId, buId, baskets: basketData?.baskets || [] });

		return newData;
	}, [productId, distributorId, basketData, buId]);

	const handleChange = ({ amount, method }: HandleChangeParams) => {
		if (!amount && method === 'add' && disableAfterAdd) {
			setLoadingValidate(true);
		}

		setQuantity((prevState) => ({
			...prevState,
			amount: manageAmountByMethod[method]?.({ value: prevState.amount, maxQuantity }) ?? amount ?? '',
			method,
		}));
	};

	const removeQuantity = async () => {
		const { amount } = quantity;

		if (debouncedQuantity.amount !== 1) {
			if (Number(amount) < 2 && !outOfContext) return setLoading('decrement');
			return handleChange({ method: 'decrement' });
		}

		setLoading('typing');
		if (!outOfContext && !!productId) await handleRemoveFromBasket(productId);
		setQuantity({ amount: 0, method: undefined });
		onUpdateBasketType?.('remove_from_cart');
		return setLoading(false);
	};

	const updateQuantityProduct = useCallback(
		async (lastAmount?: number | '') => {
			const pharmacyId = posId || pos?.id;
			if (!pharmacyId || outOfContext) return;

			const { amount, method } = debouncedQuantity || {};

			if (forcedUpdate || !method || amount === '') {
				setForcedUpdate(false);
				return;
			}

			try {
				setLoading(method);
				handleBasketLoading('refetch');
				const findChangedProduct = basketData.baskets
					?.flatMap((basket) => basket.products)
					.find((product) => product.id === productId);

				const commonData = {
					quantity: amount <= 999999 ? amount : 999999,
					distributorId,
					productId,
					suggestionGroupName: suggestionGroupName || findChangedProduct?.suggestionGroupName,
					suggestionGroupId: suggestionGroupId || findChangedProduct?.suggestionGroupId,
				};

				const data: Record<string, unknown> = productsInCombo
					? {
							...commonData,
							productsInCombo,
					  }
					: commonData;

				if (suggestionGroupId) data.suggestionGroupId = suggestionGroupId;
				if (suggestionGroupName) data.suggestionGroupName = suggestionGroupName;

				if (method === 'add') {
					const hasConflict = await validateBasketProductAmount(pharmacyId, data as any);

					if (hasConflict) {
						await handleOpenDialog({
							heading: {
								title: 'Conflito de produtos',
								showCloseButton: true,
							},
							content: {
								description: `Não foi possível adicionar esse produto ao carrinho, pois o ${hasConflict?.description} já foi adicionado anteriormente.`,
							},
							footer: {
								primaryButton: {
									label: 'Entendi',
								},
							},
						});
						setLoadingValidate(false);
						throw new CustomError('Conflito de produtos', 'duplicated');
					}

					setLoadingValidate(false);
				}
				const response = await manageBasketProductAmount(pharmacyId, data as any);

				notify.positive({ description: getManageProductMessage(method) });

				if (!!response?.quantity && response?.quantity !== amount) {
					setForcedUpdate(true);
					handleChange({ method: 'typing', amount: response.quantity });
				}

				handleGetBasketData({ loading: 'refetch' });
			} catch (error) {
				if (error === 'bad_request') await initializeBasket(pharmacyId);

				setQuantity({ amount: lastAmount || 0, method: undefined });
				notify.negative({ description: getNotificationMessage(error) });
			} finally {
				setLoading(false);
				if (!outOfContext) handleBasketLoading(false);

				const currentQuantityAsNumber = Number(quantity.amount);

				if (Number(lastAmount) < currentQuantityAsNumber) {
					if (Number(lastAmount) === 0) {
						onUpdateBasketType?.('add_to_cart', currentQuantityAsNumber);
					} else {
						onUpdateBasketType?.('add_to_cart', currentQuantityAsNumber - Number(lastAmount));
					}
				} else if (Number(lastAmount) > currentQuantityAsNumber) {
					onUpdateBasketType?.('remove_from_cart', Number(lastAmount) - currentQuantityAsNumber);
				}
			}
		},
		[debouncedQuantity, distributorId],
	);

	const spyBlurToRemove = (value: string | number) => {
		if (value === '0') setQuantity({ amount: 0, method: undefined });

		if (value === 0) removeQuantity();
	};

	useEffect(() => {
		updateQuantityProduct(initialQuantity || 0);
	}, [updateQuantityProduct]);

	useEffect(() => {
		if (quantity.amount === '' && allowEmpty) return () => {};

		onItemChange?.(quantity.amount || 0);

		return () => {};
	}, [quantity, onItemChange]);

	useEffect(() => {
		if (outOfContext) return;
		const productQuantity = productInBasket?.quantity;

		if (productQuantity) {
			setQuantity({ amount: productQuantity });
			setContextQuantity(productQuantity);
		} else {
			setQuantity({ amount: initialQuantity || 0 });
			setContextQuantity(initialQuantity || 0);
		}
	}, [productInBasket]);

	useEffect(() => {
		if (watchInitialValue) {
			setQuantity((prevState) => ({
				...prevState,
				amount: initialQuantity || 0,
			}));
		}
	}, [watchInitialValue, initialQuantity]);

	return (
		<AddToBasketButtonRender
			loading={loading}
			quantity={quantity}
			loadingBasket={outOfContext ? false : loadingBasket}
			contextQuantity={contextQuantity}
			disabled={disabled || loadingValidate}
			counterOptions={counterOptions}
			onChange={handleChange}
			onBlur={spyBlurToRemove}
			onRemove={removeQuantity}
			hasPermission={hasPermission}
			allowZero={allowZero}
			width={width}
			label={label}
			maxQuantity={maxQuantity}
			onAdd={() => {
				setLoading('add');
				handleChange({ method: 'add' });
			}}
		/>
	);
}
