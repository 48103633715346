import type { ImageProps } from "front-commons/ds/components/Image/interfaces";
import type { TextareaHTMLAttributes } from "react";
import type { CreateQuizResponse } from "services/customer/interfaces";
import type { PosLinkValidateRequest, PosLinkValidateResponse } from "services/pos/interfaces";
import type { RoleTypes } from "stores/pos/interfaces";

export type ExceptionTypes = 'CNPJ' | 'BLOCKED' | 'LAST_TRY' | 'SERVICE_UNAVAILABLE';
export type StepTypes = 'CNPJ_INPUT' | 'CUSTOMER_VALIDATION' | 'REQUEST_SIMPLE' | 'REQUEST_TABLE';

type CNPJsLinkRequestStatusTypes = "INVALID_CNPJ" | "POS_UNAVAILABLE" | "ALREADY_RELATED" | "NO_APPROVER" | "VALID" | "PENDING_VALIDATION" | "FIXED";

export type CNPJsLinkStatusTypes = "SERVICE_UNAVAILABLE" | "ACCESS_REQUESTED" | "ACCESS_GRANTED" | "ACCESS_GRANTED_MASTER" | Extract<CNPJsLinkRequestStatusTypes, 'NO_APPROVER'>;

export interface ExceptionHandlerParams {
	get: ExceptionTypes | undefined;
	set(newException: ExceptionTypes): void;
	clear(): void;
}

export interface NavigationHandlerParams {
	next(POSLinkRequest?: string[]): void;
	abort(): void;
	reset(): void;
	tryAgain(): void;
}

export interface CNPJHandlerParams {
	raw: RequestCNPJsParams[];
	set: React.Dispatch<React.SetStateAction<RequestCNPJsParams[]>>
	get(replaceData?: RequestCNPJsParams[]): {
		valid: string[];
		withException: RequestCNPJsParams[];
	}
	edit(index: number, value: string): void;
	delete(index: number[]): void;
}

export interface RoleHandler {
	readonly get: RoleTypes | undefined;
	set: React.Dispatch<React.SetStateAction<RoleTypes | undefined>>;
}

export interface StepSupportParams {
	title?: string;
	description?: string;
	imageProps?: ImageProps;
}

export interface RequestSimpleCardDataParams extends Concrete<Omit<StepSupportParams, 'description'>> {
	buttonLabel: string;
	description: React.ReactNode;
}

export interface RequestCNPJsParams {
	cnpj: string;
	index: number;
	status: CNPJsLinkRequestStatusTypes;
}

export interface LinkSummaryParams {
	NO_APPROVER: string[];
	ACCESS_GRANTED: string[];
	ACCESS_REQUESTED: string[];
	SERVICE_UNAVAILABLE: string[];
	ACCESS_GRANTED_MASTER: string[];
	total: number;
}

export interface FlowWrapperProps {
	loading?: boolean;
	children: React.ReactNode;
	linkSummary: LinkSummaryParams
	currentStep?: StepTypes;
	navigateHandler: NavigationHandlerParams;
	exceptionHandler: ExceptionHandlerParams;
}

export interface StepSupportProps extends Pick<FlowWrapperProps, 'loading' | 'currentStep' | 'exceptionHandler' | 'linkSummary'> { }

export interface SuccessProps extends Pick<FlowWrapperProps, 'navigateHandler' | 'linkSummary'> { }

export interface ExceptionsProps extends Pick<FlowWrapperProps, 'navigateHandler' | 'exceptionHandler'> { }

interface StepsLoadingCommonProps {
	loadings: {
		createQuizLoading: boolean;
		posLinkStatusLoading: boolean;
		posLinkRequestLoading: boolean;
	}
}

//! CNPJ STEP
export interface CNPJStepProps extends Pick<FlowWrapperProps, 'exceptionHandler' | 'navigateHandler'>, StepsLoadingCommonProps {
	percentage?: number;
	roleHandler: RoleHandler;
	CNPJHandler: CNPJHandlerParams;
}

interface CNPJStepCommonsProps {
	loadings: {
		validatingLoading: boolean;
	} & StepsLoadingCommonProps['loadings'];
	handleValidateCNPJs(params?: Partial<PosLinkValidateRequest>): Promise<void>
}

export interface LinkRequestProps extends Pick<CNPJStepProps, 'navigateHandler' | 'roleHandler' | 'percentage'>, CNPJStepCommonsProps {
	setRequestCNPJs: CNPJHandlerParams['set'];
}

export interface TextareaProps {
	native: TextareaHTMLAttributes<string>;
	counter: number;
	percentage?: number;
}

export interface RoleCardProps {
	label: string;
	value: RoleTypes;
	checked: boolean;
	description: string;
	onClick(value: RoleTypes): void;
}

export interface LinkExceptionFixProps extends Pick<CNPJStepProps, 'navigateHandler'>, CNPJStepCommonsProps {
	CNPJHandler: CNPJHandlerParams;
	handleIgnoreExceptions(): void
}

export interface EditableContentProps extends Pick<RequestCNPJsParams, 'index' | 'status'> {
	value: string;
	editable: boolean;
	handleEditCNPJ: LinkExceptionFixProps['CNPJHandler']['edit'];
}

//! VALIDATION STEP
export interface ValidationStepProps extends Pick<FlowWrapperProps, 'navigateHandler' | 'exceptionHandler'>, StepsLoadingCommonProps {
	quiz: CreateQuizResponse;
}

//! LINK REQUEST STEP
export interface RequestedLinkTableProps extends Pick<FlowWrapperProps, 'navigateHandler' | 'linkSummary'> { }