import { Button, Flex, Icon, Input, Typography } from 'front-commons/ds';
import { ButtonClose } from './styles';
import { FiltersProductsMobileProps } from './interfaces';
import { ButtonRemoveFilter } from 'containers/FiltersProducts/styles';
import { useEffect, useState } from 'react';
import { SelectedValues } from 'containers/FiltersProducts/interfaces';
import useDrawer from 'stores/drawer';
import { getValuesFromUrl } from 'containers/FiltersProducts/helpers';
import AccordionFilter from 'containers/Accordions/AccordionFilter';

export default function FiltersProducts({
	filters,
	handleFilterChange,
	search,
	handleClearFilter,
	divisionActive,
}: FiltersProductsMobileProps) {
	const [selectedValues, setSelectedValues] = useState<SelectedValues>([]);
	const [searchedFilter, setSearchedFilter] = useState('');
	const [filterNotFound, setFilterNotFound] = useState(false);

	const { handleCloseAllDrawers } = useDrawer();

	const handleSelectedFilters = (name?: string, value?: string) => {
		if (value) {
			setSelectedValues((prevState: any) => {
				const existingItem = prevState?.find((item: any) => item.name === name);

				let updatedValues;
				if (existingItem) {
					if (existingItem.values.includes(value)) {
						updatedValues = prevState;
					} else {
						updatedValues = prevState?.map((item: any) =>
							item.name === name ? { ...item, values: [...item.values, value] } : item,
						);
					}
				} else {
					updatedValues = [...prevState, { name, values: [value] }];
				}

				return updatedValues;
			});
		}
	};

	const handleRemoveFilter = (sectionName: string, value: string) => {
		setSelectedValues((prevState) => {
			return prevState
				.map((section) => {
					if (section.name === sectionName) {
						const t = section.values?.filter((i) => i !== value);
						return {
							...section,
							values: t?.length ? t : null,
						};
					}

					return section;
				})
				.filter((t) => t.values);
		});
	};

	const handleSearchFilter = (value: string) => {
		const findFilter = filters
			?.flatMap((filter: any) => filter.options)
			.find((option: any) => option.label.toLowerCase() === value.toLowerCase());

		if (findFilter) {
			const name = filters?.find((filter) =>
				filter.options.find((option) => option.label.toLowerCase() === value.toLowerCase()),
			)?.name;

			setFilterNotFound(false);
			handleSelectedFilters(name, findFilter.value);
		} else {
			setFilterNotFound(true);
		}
	};

	useEffect(() => {
		setSelectedValues(getValuesFromUrl(filters, search));
	}, []);

	return (
		<Flex direction="column" height="100%" justifyContent="space-between" gap="0">
			<Flex direction="column" overflow="auto">
				<Flex padding="24px 24px 0 24px" justifyContent="space-between" width="100%">
					<Flex gap="8px">
						<Icon name="filter_list" size="24px" />
						<Typography>Filtrar {divisionActive}</Typography>
					</Flex>

					<ButtonClose variant="none" onClick={handleCloseAllDrawers}>
						<Icon name="close" size="16px" />
					</ButtonClose>
				</Flex>

				<Flex padding="0 24px" gap="16px" direction="column">
					<Input
						name="search_filter"
						onChange={({ target: { value } }) => {
							if (value === ' ') return;
							setSearchedFilter(value);
							if (value.trim() === '') setFilterNotFound(false);
						}}
						value={searchedFilter}
						leftIcon={{ name: 'search', color: '--text-secondary' }}
						rightIcon={
							searchedFilter || selectedValues.length
									? {
												name: 'cancel',
												color: '--text-secondary',
												onClick: () => setSearchedFilter(''),
										}
									: undefined
						}
						placeholder="Busque pelo filtro desejado"
						onPressEnter={handleSearchFilter}
						feedback={
							filterNotFound
								? {
										type: 'error',
										message: 'Termo não encontrado',
								  }
								: undefined
						}
						autoComplete="off"
					/>

					{selectedValues.length > 0 && (
						<Flex direction="column" gap="8px">
							<Flex wrap="wrap" gap="8px">
								{selectedValues
									.flatMap((item) => item.values)
									.map((i) => {
										const findItem = filters?.flatMap((filter) => filter.options).find((option) => option.value === i);
										const findName = filters?.find((filter) =>
											filter.options.find((option) => option.value === i),
										)?.name;

										return (
											<Flex
												alignItems="center"
												justifyContent="space-between"
												borderRadius="4px"
												backgroundColor="--neutral-black-10"
												padding="8px"
												gap="8px"
											>
												<Typography variant="Paragraph/Semibold">{findItem?.label.capitalize()}</Typography>
												<ButtonRemoveFilter
													variant="none"
													onClick={() => handleRemoveFilter(findName!, findItem!.value)}
												>
													<Icon name="close" size="12px" color="--neutral-white" />
												</ButtonRemoveFilter>
											</Flex>
										);
									})}
							</Flex>

							<Button data-testid="clear-filter" onClick={() => setSelectedValues([])} variant="text">
								Limpar filtros
							</Button>
						</Flex>
					)}

					<AccordionFilter filters={filters} handleSelectedFilters={handleSelectedFilters} />
				</Flex>
			</Flex>

			<Flex padding="24px" height="80px" alignItems="center" justifyContent="center" backgroundColor="--neutral-white">
				<Button
					width="100%"
					onClick={() => {
						if (selectedValues.length > 0) {
							handleFilterChange?.(selectedValues, true);
						} else {
							handleClearFilter?.();
						}
						handleCloseAllDrawers();
					}}
				>
					Aplicar filtros
				</Button>
			</Flex>
		</Flex>
	);
}
