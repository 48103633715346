import { useEffect, useCallback } from 'react';
import { getModalData } from 'services/modal';
import { theme } from 'front-commons/ds/core/tokens';
import { useMediaQuery } from 'front-commons/hooks';
import useDialog from 'stores/dialog';
import { HandleOpenDialogProps } from 'stores/dialog/interfaces';
import usePos from 'stores/pos';
import { getDialogData } from 'routes/helpers';
import { Location } from 'react-router-dom';

export default function ModalController({ location }: { location: Location }) {
	const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.medium})`);

	const { handleCloseDialog, handleOpenDialog } = useDialog();
	const { posStore } = usePos();
	const pharmacy = posStore.selectedPos;

	const getModal = useCallback(async () => {
		const searchModal = JSON.parse(localStorage.getItem('searchModal') || 'false');

		if (searchModal && location.pathname === '/') {
			try {
				const response = await getModalData(pharmacy.id);

				const dialogData = getDialogData(response.content, isDesktop, handleCloseDialog);
				handleOpenDialog(dialogData as HandleOpenDialogProps);
			} catch (error) {
				console.log('error');
			} finally {
				localStorage.removeItem('searchModal');
			}
		}
	}, [location, pharmacy]);

	useEffect(() => {
		getModal();
	}, [getModal]);

	return null;
}
