import { createAPIConnection } from 'services';
import { getStore } from 'stores/helpers';
import {
	CreateCustomerParams,
	CreateCustomerResponse,
	ValidateEmailResponse,
	ValidateMFARegisterTokenParams,
	LoginUserParams,
	LoginUserResponse,
	ResetPasswordParams,
	CustomerDataResponse,
	UpdateCustomerDataParams,
	UpdateCustomerEmailParams,
	ValidateTokenUpdateCustomerEmailParams,
	CreateQuizResponse,
	ValidateQuizResponse,
	ValidateQuizRequest,
	Validate2FAOTPResponse,
	ValidateCustomer2FAResponse,
	GetSerasaStatusResponse,
} from './interfaces';
import { requestHandler } from '../helpers';
import { ResponseParams } from '../interfaces';

export const createCustomer = async (body: CreateCustomerParams) => {
	return requestHandler(createAPIConnection('/customers').post<ResponseParams<CreateCustomerResponse>>('', body));
};

export const loginCustomer = async (body: LoginUserParams) => {
	const response = await createAPIConnection('/customers').post<LoginUserResponse>(
		`/login${window.location.pathname === '/rv' ? '?from=rv' : ''}`,
		body,
		{
			baseURL: import.meta.env.VITE_API_BASE,
		},
	);

	return response?.data;
};

export const validateCustomerEmail = async (email: string) => {
	const response = await createAPIConnection('/customers').post<ResponseParams<ValidateEmailResponse>>('/validate', {
		email,
	});
	return response?.data?.content;
};

export const createQuiz = async () =>
	requestHandler<CreateQuizResponse>(createAPIConnection('/customers').get('/serasa/quiz/create'), {
		throwData: true,
	});

export const validateQuiz = (quiz: ValidateQuizRequest) =>
	requestHandler<ValidateQuizResponse>(createAPIConnection('/customers', {
		onReject() {
			throw new Error('Erro ao validar quiz');
		},
	}).post(`/serasa/quiz/result`, quiz));

export const validateCustomerCpf = async (cpf: string) => {
	const response = await createAPIConnection('/customers').post<ResponseParams<ValidateEmailResponse>>(
		'/cpf/validate',
		{ cpf },
	);
	return response?.data?.content;
};

export const validateMFARegisterToken = async ({ validationCode, validationKey }: ValidateMFARegisterTokenParams) => {
	const response = await createAPIConnection('/customers').post<ResponseParams<void>>(
		'/token/validate',
		{ validationCode },
		{ headers: { Authorization: validationKey } },
	);
	return response?.data;
};

export const generateMFARegisterToken = async (validationKey: string) => {
	const response = await createAPIConnection('/customers').post<ResponseParams<void>>('/token/generate', undefined, {
		headers: { Authorization: validationKey },
	});
	return response?.data;
};

export const getUserData = async () => {
	return requestHandler<CustomerDataResponse>(
		createAPIConnection('/customers').get('', {
			headers: {
				Authorization: getStore().customerReducer.token!,
			},
		}),
	);
};

export const requestResetPassword = async (email: string) => {
	return requestHandler(createAPIConnection('/customers').post('/request/reset/password', { email }));
};

export const resetPassword = async (body: ResetPasswordParams) => {
	return requestHandler(createAPIConnection('/customers').post('/reset/password', body));
};

export const updateUserData = async (body: UpdateCustomerDataParams) => {
	return requestHandler(createAPIConnection('/customers').put('/update', body));
};

export const requestUpdateUserEmail = async (body: UpdateCustomerEmailParams) => {
	return requestHandler(createAPIConnection('/customers').post('/email/update', body));
};

export const validateTokenUpdateUserEmail = async (body: ValidateTokenUpdateCustomerEmailParams) => {
	return requestHandler(createAPIConnection('/customers').post('/email/update/token/validate', body));
};

export const requestPasswordUpdate = async () => {
	return requestHandler(createAPIConnection('/customers').post('/password/update', {}));
};

export const generate2FAOTP = () => requestHandler(createAPIConnection('/customers').post('/2FA/token/send'));

export const validate2FAOTP = (token: string) =>
	requestHandler<Validate2FAOTPResponse>(createAPIConnection('/customers').post('/2FA/token/validate', { token }));

export const validateCustomer2FA = () =>
	requestHandler<ValidateCustomer2FAResponse>(createAPIConnection('/customers').get('/2FA/validate'));

export const logout = async () =>
	requestHandler(createAPIConnection('/').post('logout'));

// export const getAccessPending = async () => {
// 	return requestHandler(createAPIConnection('/management').post('/access/pending', {}));
// };

export const getSerasaStatus = () =>
	requestHandler<GetSerasaStatusResponse>(createAPIConnection('/customers').get('/serasa/status'), { throwData: true });
