import PromotionDetailDrawerContent from 'containers/Drawers/PromotionDetailDrawerContent';
import PromotionsDrawerContent from 'containers/Drawers/PromotionsDrawerContent';
import UnavailablePromotionDetailDrawerContent from 'containers/Drawers/UnavailablePromotionDetailDrawerContent';
import { DrawerReducer, DrawersId, RegisteredDrawerParams } from './interfaces';
import DistributorChange from 'containers/Drawers/DistributorChange';
import DistributorChangeDrawerHeader from 'containers/Drawers/DistributorChange/Header';
import PromotionSuggestion from 'containers/Drawers/PromotionSuggestion';
import ImportPromotionDetailDrawerContent from 'containers/Drawers/ImportPromotionDetailDrawerContent';
import SideBasketHeader from 'containers/Headers/SideBasket';
import { SideBasket } from 'containers/Drawers/SideBasket';
import UpdatePassword from 'containers/Notification/UpdatePassword';
import PrivacyPolicyMenuDrawer from 'containers/Drawers/PrivacyPolicyMenuDrawer';
import DistributorAttributing from 'containers/Drawers/DistributorAttributing';
import React from 'react';
import FiltersProducts from 'containers/Drawers/FiltersProducts';
import DistributorChangeCatalog from 'containers/Drawers/DistributorChangeCatalog';

export const initialTitles: DrawerReducer['dynamicTitle'] = [
	{
		drawerId: 'distributor-change-drawer',
		title: 0,
	},
];

export const registeredDrawers: Record<DrawersId, RegisteredDrawerParams> = {
	'promotions-drawer': {
		title: 'Promoções com o produto',
		children: <PromotionsDrawerContent />,
		open: false,
		maxWidth: '704px',
		width: '100%',
	},
	'promotion-details-drawer': {
		title: 'Detalhe da promoção',
		children: <PromotionDetailDrawerContent />,
		open: false,
		maxWidth: '704px',
		padding: '0',
		showHeader: false,
		index: 2,
	},
	'unavailable-promotion-details-drawer': {
		title: 'Detalhe da promoção',
		children: <UnavailablePromotionDetailDrawerContent />,
		open: false,
		maxWidth: '704px',
	},
	'import-promotion-details-drawer': {
		title: 'Detalhe da promoção',
		children: React.createElement(ImportPromotionDetailDrawerContent),
		open: false,
		maxWidth: '704px',
		padding: '0',
		showHeader: false,
		index: 2,
	},
	'distributor-change-drawer': {
		title: <DistributorChangeDrawerHeader />,
		children: <DistributorChange />,
		open: false,
		maxWidth: '704px',
	},
	'promotion-suggestion': {
		open: false,
		title: 'Detalhe da promoção',
		maxWidth: '700px',
		children: <PromotionSuggestion />,
	},
	'basket-drawer': {
		title: <SideBasketHeader />,
		children: <SideBasket />,
		open: false,
		maxWidth: '500px',
		index: 1,
	},
	'update-password-drawer': {
		title: 'Alterar senha',
		children: <UpdatePassword />,
		openFromBottom: true,
		open: false,
		maxWidth: '500px',
		index: 1,
		height: '300px',
	},
	'privacy-policy-menu-drawer': {
		title: '  ',
		children: <PrivacyPolicyMenuDrawer />,
		open: false,
		maxWidth: '500px',
		side: 'left',
		index: 1,
	},
	'distributor-attributing': {
		title: null,
		children: React.createElement(DistributorAttributing),
		open: false,
		maxWidth: '648px',
		side: 'right',
		showHeader: false,
		padding: '0',
		index: 1,
	},
	'filters-products-mobile': {
		title: null,
		children: <FiltersProducts />,
		open: false,
		width: '100%',
		height: '95%',
		borderRadius: '24px 24px 0 0',
		side: 'bottom',
		padding: '0',
	},
	'catalog-distributor-change': {
		title: null,
		children: React.createElement(DistributorChangeCatalog),
		open: false,
		maxWidth: '704px',
		side: 'right',
		showHeader: false,
		padding: '0',
		index: 1,
	},
};
