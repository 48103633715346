import { createAPIConnection } from 'services';
import { requestHandler } from 'services/helpers';
import { GetModalDataResponse } from './interfaces';

const api = createAPIConnection('/modal');

export const getModalData = async (posId: string, areaId?: string) =>
	requestHandler<GetModalDataResponse>(api.get(`/pos/${posId}${areaId ? `?areaId=${areaId}` : ""}`));

export const getModalAreas = async () =>
	requestHandler<Pick<ModalEntity["area"], "id" | "name" | "tag">[]>(api.get(`/areas`));