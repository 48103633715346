import { useSelector, useDispatch } from 'react-redux';
import * as middleware from './middleware';
import { ModalAreaTag } from './interfaces';
import { useCallback } from 'react';

export default function useModal() {
	const dispatch = useDispatch();
	const modalStore = useSelector((stores: ReduxStore) => stores.modalReducer);

	const getModalAreaByTag = useCallback((tag: ModalAreaTag) => {
		return modalStore.areas?.find((area) => area.tag === tag);
	}, [modalStore.areas])

	return {
		modalStore,
		handleSetModalAreas: () => dispatch(middleware.handleSetModalAreas()),
		getModalAreaByTag
	};
}
