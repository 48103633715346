import { Flex, Icon, Typography } from 'front-commons/ds';
import { ThemeColorsTypes } from 'front-commons/ds/core/shared/interfaces';
import { CONTACT_INFO } from 'config/constants';

interface ContactUsProps {
	color?: ThemeColorsTypes;
	iconsColor?: ThemeColorsTypes;
	phoneColor?: ThemeColorsTypes;
	phoneUnderline?: boolean;
	hide?: Array<'openingHours' | 'phones' | 'email'>;
	override?: {
		openingHours?: React.ReactNode;
		phones?: React.ReactNode;
		email?: React.ReactNode;
	};
	mobilePhoneAdaptation?: boolean;
}

export default function ContactUs({
	override,
	color,
	iconsColor = color,
	phoneColor = color,
	phoneUnderline,
	hide,
	mobilePhoneAdaptation,
}: ContactUsProps) {
	const variant = phoneUnderline ? 'Paragraph/Underline' : undefined;
	const adapt = {
		phoneContainerDirection: mobilePhoneAdaptation ? { small: 'column', medium: 'row' } : 'row',
		phoneSeparator: mobilePhoneAdaptation ? undefined : { padding: '0 0 0 16px', border: '1px solid' },
	};

	return (
		<Flex direction="column" gap="16px">
			{!hide?.includes('openingHours') && (
				<Typography color={color} style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
					<Icon color={iconsColor} name="schedule" />
					{override?.['openingHours'] || CONTACT_INFO.openingHours}
				</Typography>
			)}

			{!hide?.includes('phones') && (
				<Flex gap="16px" direction={adapt.phoneContainerDirection as any}>
					{override?.['phones'] || (
						<>
							<Flex gap="8px" alignItems="center" as="a" href={CONTACT_INFO.phones.whatsapp.link}>
								<Icon color={iconsColor} name="whatsapp" />
								<Typography variant={variant} color={phoneColor}>
									{CONTACT_INFO.phones.whatsapp.masked}
								</Typography>
							</Flex>
							<Flex gap="16px" {...adapt.phoneSeparator} borderWidth="0 0 0 1px" borderColor={color}>
								<Flex gap="8px" alignItems="center" as="a" href={CONTACT_INFO.phones.call.link}>
									<Icon color={iconsColor} name="call" />
									<Typography variant={variant} color={phoneColor}>
										{CONTACT_INFO.phones.call.masked}
									</Typography>
								</Flex>
							</Flex>
						</>
					)}
				</Flex>
			)}

			{!hide?.includes('email') && (
				<Typography
					color={color}
					as="a"
					href={CONTACT_INFO.email.link}
					style={{ display: 'flex', gap: '8px', alignItems: 'center' }}
				>
					<Icon color={iconsColor} name="mail" />
					{override?.['email'] || CONTACT_INFO.email.raw}
				</Typography>
			)}
		</Flex>
	);
}
