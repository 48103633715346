import styled from 'styled-components';
import * as RadixAccordion from "@radix-ui/react-accordion"

export const AccordionRoot = styled(RadixAccordion.Root)`
	display: flex;
	flex-direction: column;
	gap: 5px;
`;

export const AccordionItem = styled(RadixAccordion.Item)``;

export const AccordionContent = styled(RadixAccordion.Content)`
	max-height: 400px;
	overflow-y: auto;
`;

export const AccordionTrigger = styled(RadixAccordion.Trigger)`
	display: flex;
	width: 100%;
	align-items: center;
	cursor: pointer;
	justify-content: space-between;
	margin-top: 8px;
	padding: 8px 0 0 0;
	border-top: 1px solid var(--greys-200);

	i {
		transform: rotate(90deg);
		transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
	}

	&[data-state='open'] i {
		transform: rotate(270deg);
	}
`;