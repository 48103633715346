import { AccordionContent, AccordionItem, AccordionRoot, AccordionTrigger } from './styles';
import { Button, Flex, Icon, Typography } from 'front-commons/ds';
import { AccordionFilterProps } from './interfaces';

export default function AccordionFilter({ filters, handleSelectedFilters }: AccordionFilterProps) {
	return (
		<AccordionRoot type="multiple">
			{filters?.map((filter) => (
				<AccordionItem value={filter.name}>
					<AccordionTrigger>
						<Typography variant="Paragraph/Semibold" color="--text-link">
							{filter.title}
						</Typography>
						<Icon name="chevron_right" size="24px" color="--text-link" />
					</AccordionTrigger>
					<AccordionContent>
						<Flex direction="column" gap="8px" margin="16px 0 0 0">
							{filter.options.map((option) => (
								<Button variant="none" onClick={() => handleSelectedFilters(filter.name, option.value)}>
									<Typography variant="Paragraph/Regular">{option.label.capitalize()}</Typography>
								</Button>
							))}
						</Flex>
					</AccordionContent>
				</AccordionItem>
			))}
		</AccordionRoot>
	);
}
