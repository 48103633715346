import { getPropertyByMedia, getThemeFontSize, getThemeLineHeight, getThemeSpace } from 'front-commons/ds';
import styled, { css } from 'styled-components';
import { SuggestedProductTagParams } from './interfaces';

export const SuggestedProductTagContainer = styled.div<SuggestedProductTagParams>`
	background-color: var(${({ bgColor }) => bgColor || '--semantic-warning-base'});
	padding: ${getThemeSpace('4')} ${getThemeSpace('8')};
	border-radius: ${({ fullRadius }) => (fullRadius ? '4px' : '0px 8px 0px 8px')};
	font-size: ${getThemeFontSize('14')};
	line-height: ${getThemeLineHeight('tightest')};
	color: var(${({ color }) => color || '--text-invert'});
	height: 24px;

	${({ width }: any) => getPropertyByMedia('width', width, 'initial')};

	${({ ignorePosition }) =>
		!ignorePosition &&
		css`
			position: absolute;
			right: 0;
			top: 0;
		`}

	${({ topLeft }) =>
		topLeft === true &&
		css`
			position: relative;
			margin: -17px 0 0 -8px;
		`}
`;
