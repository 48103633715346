import { Dispatch } from '@reduxjs/toolkit';
import { setModalAreas } from './actions';
import { getModalAreas } from 'services/modal';

export const handleSetModalAreas = (): any => {
	return async (dispatch: Dispatch) => {
		try {
			const data = await getModalAreas();
			dispatch(setModalAreas(data));
		} catch (error) {
			console.log(error);
		}
	};
};
