const parseEnvBoolean = (key: string) => {
	const value = import.meta.env[key] || 'false';
	let response = false;

	try {
		response = JSON.parse(value);
	} catch {
		response = false;
	}

	return response;
};

export const featureGuideExpiration = {
	IMPORT_SUPERVISOR_FEATURES_RELEASE: new Date(2025, 0, 4)
};

const features = {
	POS_REGISTER: parseEnvBoolean('VITE_POS_REGISTER'),
	GRID_COLUMNS_OVERLAY: parseEnvBoolean('VITE_GRID_COLUMNS_OVERLAY'),
	CUSTOMER_DATA_EMAIL_CHANGE: parseEnvBoolean('VITE_CUSTOMER_DATA_EMAIL_CHANGE'),
	MANDATORY_CUSTOMER_PHONE_REGISTER: parseEnvBoolean('VITE_MANDATORY_CUSTOMER_PHONE_REGISTER'),
	PROMOTION_SUGGESTION_CATALOG_PRODUCT_REVISION: parseEnvBoolean('VITE_PROMOTION_SUGGESTION_CATALOG_PRODUCT_REVISION'),
	AB_TEST: '',
	VITE_MANDATORY_ZECA_GATE: parseEnvBoolean('VITE_MANDATORY_ZECA_GATE'),
};

export default features;
