import styled from 'styled-components';
import { Button, getThemeSpace } from 'front-commons/ds';

export const ButtonRemoveFilter = styled(Button)`
	display: flex;
	background-color: var(--greys-300);
	padding: 2px !important;
	border-radius: 16px;
`;

export const ButtonFilterMobile = styled(Button)`
	display: flex;
	justify-content: space-between;
	align-items: center;

	width: 100%;
	height: 56px;
	gap: ${getThemeSpace('8')};

	background-color: var(--neutral-white) !important;
	border: 1px solid var(--greys-100);
`;