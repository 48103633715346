import { Button, Flex, Icon, notify, Tooltip, Typography } from 'front-commons/ds';
import { useDebounce, useWhenMounted, useDynamicLoading } from 'front-commons/hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddToBasketButton from 'containers/AddToBasketButton';
import usePromotionContext from 'contexts/Promotion';
import { updateOrderImportPromotion } from 'services/import';
import useDialog from 'stores/dialog';
import useDrawer from 'stores/drawer';
import useOrderImport from 'stores/orderImport';
import { PromotionsProps } from 'stores/orderImport/interfaces';
import {
	ImportPromotionsActionButtonsAddButtonProps,
	ImportPromotionsActionButtonsProps,
	LoadingType,
} from './interfaces';
import { hasQuantityChanged as handleHasQuantityChanged, hasUnableToUpdate } from 'shared/promotionDrawer';
import useCustomer from 'stores/customer';
import useBasket from 'stores/basket';
import { FlexTooltip } from './styles';

export default function ImportPromotionsActionButtons({
	posId,
	isFlex = false,
	hasValue,
	promotionId,
	comboProducts,
	distributorId,
	availableLimit,
	suggestionGroupId,
	suggestionGroupName,
	hasValidDistributors,
	orderImportProductId,
	getOrderImportProductId,
	progressiveDiscountProductMix,
	minQuantityDistinctProducts,
	productsSummary,
	prevData,

	handleSetDialogState,
	handleRefetchItemList,
	handleQuantityImportChange,
}: ImportPromotionsActionButtonsProps) {
	const navigate = useNavigate();

	const { handleLoading } = useDynamicLoading<LoadingType>();
	const {
		orderImportStore: { posPromotions },
		handleAddPosPromotions,
		handleRemovePosSinglePromotion,
	} = useOrderImport();
	const { handleCloseAllDrawers } = useDrawer();
	const { closePromotionDrawer } = usePromotionContext();
	const { handleOpenDialog } = useDialog();
	const { handleHasPermission } = useCustomer();

	const importData = posPromotions?.[posId]?.[promotionId];
	const hasInList = !!importData;
	const isInvalid = false;
	const validProductsCount = productsSummary.filter((product: any) => product.quantity > 0).length;
	const isValidQuantity = validProductsCount >= minQuantityDistinctProducts!;

	const prevProductsSummaryRef = useRef<any[]>([]);
	const hasPermission = handleHasPermission('BASKET_EDIT');

	useEffect(() => {
		prevProductsSummaryRef.current = productsSummary;
	}, [productsSummary]);

	const checkQuantityChange = (): boolean => {
		return productsSummary.some((product: any, index: number) => {
			const prevProduct = prevProductsSummaryRef.current[index];
			return prevProduct && product.quantity !== prevProduct.quantity;
		});
	};

	const handleOpenConflictModal = () => {
		handleOpenDialog({
			heading: {
				title: 'Conflito de produtos',
				showCloseButton: true,
			},
			content: {
				description:
					'Não foi possível adicionar essa promoção, pois um dos itens incluídos já faz parte de outra promoção.',
			},
			footer: {
				primaryButton: { label: 'Entendi' },
			},
		});
	};

	const handleAdd = async () => {
		if (!hasValue) {
			handleSetDialogState('no_value');
			return;
		}

		try {
			handleLoading().set(['quantity']);

			await updateOrderImportPromotion({
				orderImportProductId,
				distributorId,
				quantity: 1,
				removed: false,
				comboId: promotionId,
				comboProducts,
			});

			handleCloseAllDrawers();
			closePromotionDrawer();

			handleAddPosPromotions({
				posId,
				promotionId,
				payload: {
					products: comboProducts,
					orderImportProductId,
					quantity: 1,
					distributorId,
				},
			});
			handleRefetchItemList?.();
			notify.positive({ description: 'Promoção adicionada com sucesso.' });
		} catch (error) {
			const err = error as any;

			if (err.status === 'not_acceptable') {
				notify.negative({ description: 'Importação cancelada.' });
				navigate('/importacao-pedido');
				return;
			}

			if (err.status === 'bad_request') {
				handleOpenConflictModal();
				return;
			}
			notify.negative({ description: 'Não foi possível adicionar essa promoção! Por favor, tente novamente.' });
		} finally {
			handleLoading().remove(['quantity']);
		}
	};

	const handleUpdate = async (payload: Partial<PromotionsProps & { removed: boolean }>) => {
		const loadingState = payload.removed ? 'remove' : 'quantity';

		try {
			handleLoading().set([loadingState]);
			const promotionInfos = payload.removed ? undefined : { comboProducts };

			await updateOrderImportPromotion({
				orderImportProductId: getOrderImportProductId?.() || orderImportProductId,
				distributorId,
				quantity: 1,
				removed: !hasValue,
				...promotionInfos,
				...payload,
			});

			if (payload.removed || !hasValue || payload.quantity === 0) {
				handleRemovePosSinglePromotion({ posId, promotionId });
				handleRefetchItemList?.();
				notify.positive({ description: 'Promoção removida com sucesso.' });
				handleQuantityImportChange?.(0);
				handleCloseAllDrawers();
				closePromotionDrawer();
				return;
			}

			handleAddPosPromotions({
				posId,
				promotionId,
				payload: {
					products: comboProducts,
					quantity: 1,
					distributorId,
					orderImportProductId: getOrderImportProductId?.() || orderImportProductId,
					...payload,
				},
			});

			handleRefetchItemList?.();
			handleQuantityImportChange?.(payload.quantity || 1);
			notify.positive({ description: 'Promoção editada com sucesso.' });
		} catch (error) {
			const errorResponse = error as any;

			if (errorResponse.status === 'not_acceptable') {
				notify.negative({ description: 'Importação cancelada.' });
				navigate('/importacao-pedido');
				return;
			}

			if (errorResponse.code === 400 && errorResponse.status === 'bad_request') {
				handleOpenConflictModal();
				return;
			}

			notify.negative({ description: 'Falha ao atualizar promoção' });
		} finally {
			handleLoading().remove([loadingState]);
		}
	};

	const addToCartLabel = useMemo(() => {
		if (minQuantityDistinctProducts) {
			if (isValidQuantity) return 'Adicionar promoção';
			return 'Promoção bloqueada';
		}

		if (checkQuantityChange()) {
			if (!isValidQuantity) return 'Promoção bloqueada';
			return 'Atualizar';
		}

		return 'Adicionar';
	}, [minQuantityDistinctProducts, prevProductsSummaryRef, comboProducts.length, isValidQuantity]);

	const renderFlexBtn = () => {
		return (
			<Button
				width={{
					small: '100%',
					medium: ['Promoção bloqueada'].includes(addToCartLabel) ? '236px' : '167.65px',
				}}
				onClick={handleAdd}
				loading={handleLoading().get(['quantity'])}
				leftIcon={
					addToCartLabel === 'Promoção bloqueada'
						? {
								name: 'lock',
								color: '--text-invert',
								detached: !!minQuantityDistinctProducts,
						  }
						: undefined
				}
				hasPermission={hasPermission}
				disabled={(!isValidQuantity && !checkQuantityChange()) || ['Promoção bloqueada'].includes(addToCartLabel)}
			>
				{addToCartLabel}
			</Button>
		);
	};

	const renderprogressiveDiscountBtn = () => {
		return (
			<Flex width="100%" justifyContent="space-between" alignItems="center">
				<Flex gap="8px" width="100%" alignItems="center">
					<Typography variant="ParagraphSmall/Regular" color="--text-secondary">
						{validProductsCount} /{' '}
						<Typography variant="ParagraphSmall/Semibold" color="--text-primary">
							{minQuantityDistinctProducts}
						</Typography>{' '}
						Produtos para desbloquear
					</Typography>

					<FlexTooltip>
						<Tooltip
							content="É necessário a escolha de produtos distintos para desbloquear a promoção."
							maxWidth="174px"
						>
							<Icon name="info" color="--text-primary" size="16px" fill />
						</Tooltip>
					</FlexTooltip>
				</Flex>
				<Flex width="100%" justifyContent="end" gap="8px" alignItems="center">
					{hasInList && hasPermission && (
						<Button
							loading={handleLoading().get(['remove'])}
							disabled={handleLoading().get(['quantity'])}
							variant="secondary"
							onClick={() => handleUpdate({ removed: true })}
							width={{ medium: '100%' }}
							maxWidth={{ medium: '160px' }}
							height="40px"
						>
							Remover
						</Button>
					)}
					{hasInList && hasPermission && isValidQuantity && isValidQuantity !== undefined ? (
						<ImportPromotionsActionButtons.ManageButtons
							isFlex={isFlex}
							loading={handleLoading().get(['quantity'])}
							disabled={handleLoading().get(['quantity', 'remove']) || isInvalid}
							importData={importData}
							comboProducts={comboProducts}
							availableLimit={availableLimit}
							onQuantityChange={(quantity) => handleUpdate({ quantity })}
							suggestionGroupId={suggestionGroupId}
							suggestionGroupName={suggestionGroupName}
							isValidQuantity={isValidQuantity}
						/>
					) : (
						renderFlexBtn()
					)}
				</Flex>
			</Flex>
		);
	};

	const renderDefaultComponent = () => {
		return (
			<Flex justifyContent="flex-end" margin="0 0 32px 0" gap="16px" width="100%">
				{hasInList && (
					<Button
						loading={handleLoading().get(['remove'])}
						disabled={handleLoading().get(['quantity'])}
						variant="secondary"
						onClick={() => handleUpdate({ removed: true })}
						width={{ medium: '100%' }}
						maxWidth={{ medium: '165.61px' }}
						height="40px"
					>
						Remover promoção
					</Button>
				)}
				{hasInList ? (
					hasPermission && (
						<ImportPromotionsActionButtons.ManageButtons
							isFlex={isFlex}
							loading={handleLoading().get(['quantity'])}
							disabled={handleLoading().get(['quantity', 'remove']) || isInvalid}
							importData={importData}
							comboProducts={comboProducts}
							availableLimit={availableLimit}
							onQuantityChange={(quantity) => handleUpdate({ quantity })}
							suggestionGroupId={suggestionGroupId}
							suggestionGroupName={suggestionGroupName}
						/>
					)
				) : (
					<Button
						disabled={!hasValidDistributors || handleLoading().get(['remove'])}
						loading={handleLoading().get(['quantity'])}
						onClick={handleAdd}
						width="100%"
						size="large"
						maxWidth={{ medium: '167.75px' }}
					>
						Adicionar promoção
					</Button>
				)}
			</Flex>
		);
	};

	return progressiveDiscountProductMix && minQuantityDistinctProducts
		? renderprogressiveDiscountBtn()
		: renderDefaultComponent();
}

ImportPromotionsActionButtons.ManageButtons = ({
	isFlex,
	loading,
	disabled,
	importData,
	comboProducts,
	availableLimit,
	suggestionGroupId,
	suggestionGroupName,
	isValidQuantity,
	onQuantityChange,
}: ImportPromotionsActionButtonsAddButtonProps) => {
	const { summary } = usePromotionContext();
	const [quantity, setQuantity] = useState(importData.quantity || 0);

	const someQuantityHasChanged = useMemo(() => {
		return (comboProducts || summary).some((productSummary) => {
			const importedProduct = importData.products.find((imported) => imported.productId === productSummary.productId);
			if (!importedProduct) return productSummary.quantity !== 0;

			return importedProduct.quantity !== productSummary.quantity;
		});
	}, [importData, summary, comboProducts]);

	const debouncedQuantity = useDebounce(quantity, 500);

	useWhenMounted(() => {
		onQuantityChange(debouncedQuantity);
	}, [debouncedQuantity]);

	if (!isFlex)
		return (
			<Button
				width="167.75px"
				disabled={!someQuantityHasChanged || disabled || (!isValidQuantity && isValidQuantity !== undefined)}
				loading={loading}
				onClick={() => onQuantityChange(quantity)}
				height="40px"
			>
				Atualizar promoção
			</Button>
		);

	return (
		<AddToBasketButton
			hasPermission
			initialQuantity={quantity}
			outOfContext
			allowZero
			allowEmpty
			onItemChange={setQuantity}
			counterOptions={{
				hasPermission: true,
				loading: loading ? 'typing' : undefined,
				disabled: disabled || availableLimit === 0,
				width: '167.75px',
			}}
			maxQuantity={availableLimit}
			suggestionGroupId={suggestionGroupId}
			suggestionGroupName={suggestionGroupName}
		/>
	);
};
