import type { ModalReducer } from './interfaces';

const initialState: ModalReducer = {
	areas: undefined,
};

export default function modalReducer(state = initialState, { type, payload }: any) {
	switch (type) {
		case 'SET_MODAL_AREAS': {
			return { ...state, areas: payload.areas };
		}

		case 'CLEAR_MODAL': {
			return initialState;
		}

		default:
			return state;
	}
}
