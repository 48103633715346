import {
  pluckFirst,
  useObservable,
  useObservableCallback,
  useSubscription,
} from 'observable-hooks'
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  Observable,
  catchError,
  map,
  mapTo,
  mergeAll,
  of,
  retryWhen,
  switchMap,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs'
import { ChatFirebaseConfig, Message, MimoId, Product } from '../../Types'
import DefaultModal from '../../components/defaultModal/DefaultModal'
import { useToaster } from '../../components/toaster/Toaster'
import { API_VERSION } from '../../consts'
import { useFirebase, useList, useObjectVal } from '../../hooks/useFirebase'
import { useScreenSize } from '../../hooks/useScreenSize'
import { jsonFetch } from '../../http/http'
import { genericRetryStrategy } from '../../http/retryEstrategy'
import { useModal } from '../../modal'
import { ChatFormModal } from '../../modals/chatFormModal/ChatFormModal'
import { useActionLoop, useSelector } from '../../store/Store'
import { ChatUser, chatReducer } from '../../store/chatReducer'
import { generateRandomNumber, generateURL } from '../../utils/FormatUtil'
import { ChatProviderContext } from '../ChatProviders'
import { useConfig } from '../ConfigProvider'
import { useEmbedParams } from '../EmbedParamsProvider'
import { useTracking } from '../TrackingProvider'

const configRetryStrategy = genericRetryStrategy({
  maxRetryAttempts: 1,
})

export function ChatFirebaseProvider({
  config,
  children,
}: PropsWithChildren<{
  config: ChatFirebaseConfig
}>) {
  const { id: liveId } = useConfig()
  const params = useEmbedParams()

  const dispatch = useDispatch()
  // const { isMobile } = useDevice()
  const { isMobile } = useScreenSize()

  // User data
  const userData = useSelector((s) => s.chat.user)
  const setUserData = useCallback(
    (v: ChatUser) => dispatch(chatReducer.actions.setUser(v)),
    [dispatch]
  )

  // Chat visibility
  const visible = useSelector((v) => v.chat.visible)
  const setVisible = useCallback(
    (v: boolean) => dispatch(chatReducer.actions.setVisibility(v)),
    [dispatch]
  )

  const pendingMsgRef = useRef<string | null>(null)

  const firebase = useFirebase('chat', config.meta)

  const [like, likesDiff$, isSendingLike] = useLike(firebase, config)

  const { t } = useTranslation()

  const device = {
    initial: parseInt(
      isMobile
        ? import.meta.env.VITE_CHAT_NUMBER_MESSAGES_MOB
        : import.meta.env.VITE_CHAT_NUMBER_MESSAGES_DESK
    ),
    limit: parseInt(
      isMobile
        ? import.meta.env.VITE_CHAT_LIMIT_MESSAGES_MOB
        : import.meta.env.VITE_CHAT_LIMIT_MESSAGES_DESK
    ),
  }
  const chatMessageLimit = params.chatMessagesLimit || 10

  // Messages
  const [_messages] = useList(
    firebase
      ?.database()
      .ref('chat')
      .limitToLast(device.initial || chatMessageLimit)
  )

  const msgCache = useRef<Message[]>([])

  const messages: Message[] = useMemo(() => {
    msgCache.current = normalizeMessageCache<Message>(
      msgCache.current,
      (_messages || [])?.map((v) => {
        return { ...v.val(), id: v.key } as Message
      })
    )
      .sort((a, b) => a.t - b.t)
      .filter((msg) => {
        //Mensagens desabilitadas são mostradas para o usuário logado (p/ ele não perceber que foi bloqueado)
        if (userData?.id) {
          return !(msg.disabled && msg.uid != userData.id)
        } else {
          return !msg.disabled
        }
      })
      .slice(-device.limit)

    return msgCache.current
  }, [_messages, userData])

  const [_pinned_messages] = useList(
    firebase?.database().ref('pinned_messages')
  )
  const pinnedMessages = normalizePinnedMessage(_pinned_messages, messages)
  // convert user into observable
  const user$ = useObservable(pluckFirst, [userData])
  const { i18n } = useTranslation()

  const currentLang = i18n.language

  if (userData) {
    localStorage.setItem(
      `__mimo__${liveId}__termAccept`,
      JSON.stringify({ termAccept: userData.termAccept })
    )
  }

  const [isSendingMessage, setIsSendingMessage] = useState(false)
  const [showToaster] = useToaster()
  const tracking = useTracking()
  const customChatAttributes = !config.allow_chat_anonymous_user
    ? config?.custom_chat_attributes
    : undefined

  // POST - send message
  const [postMessage, postMessage$] = useObservableCallback<string, string>(
    ($msgs) =>
      $msgs.pipe(
        withLatestFrom(user$),
        switchMap(([msg, user]) => {
          setIsSendingMessage(true)
          return jsonFetch<{ message: string; userId: string }>(
            generateURL(config.meta.endpoints.sendMessage, {
              MIMO_LIVE_ID: liveId!,
              MIMO_API_VERSION: API_VERSION,
            }),
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                message: msg,
                liveId: liveId,
                language: currentLang,
                name: user?.name || null,
                email: user?.email || null,
                phone: user?.phone || null,
                nickname: user?.nickname || null,
                is_whatsapp: user?.is_whatsapp ?? false,
                custom_attributes: user?.custom_attributes || null,
              }),
            }
          ).pipe(
            tap((values) => {
              setIsSendingMessage(false)

              // userId from api /sendMessage
              if (user && values.userId) {
                setUserData({ ...user, id: values.userId })
                tracking.track({
                  event: 'chat_message',
                  chat_user_id: values.userId,
                })
              }
            }),
            catchError((err) => {
              console.error(err)
              showToaster(t('could_not_send_message'))
              setIsSendingMessage(false)
              return of('ok')
            }),
            mapTo('ok')
          )
        })
      )
  )

  useSubscription(postMessage$)

  // send message loop
  const sendMessageIntent = useActionLoop(
    chatReducer.actions.intent_ChatMessageSend,
    (action) => {
      sendMessage(action.payload)
    }
  )

  const formModal = useModal({
    component: (
      <DefaultModal title={t('identification').toUpperCase()}>
        <ChatFormModal
          loading={false}
          chatForm={customChatAttributes}
          onSubmit={(v) => {
            setUserData(v)
          }}
        />
      </DefaultModal>
    ),
    open: false,
  })

  const openModal = formModal.open
  const closeModal = formModal.close

  const sendMessage = useCallback(
    (msg: string) => {
      if (userData) {
        !visible && setVisible(true)
        postMessage(msg)
        closeModal()
      } else {
        if (config.allow_chat_anonymous_user) {
          const userNumber = generateRandomNumber().toString()
          const userFake = {
            name: `${t('user')}${userNumber}`,
            email: `${t('user')}${userNumber}@mimochat.com.br`,
            termAccept: true,
          }
          setUserData(userFake)
          pendingMsgRef.current = msg
        } else {
          openModal()
          pendingMsgRef.current = msg
        }
      }
    },
    [
      userData,
      openModal,
      closeModal,
      postMessage,
      config,
      setVisible,
      setUserData,
      visible,
      pendingMsgRef,
    ]
  )

  // send pending message when user is set
  useEffect(() => {
    if (pendingMsgRef.current) {
      sendMessage(pendingMsgRef.current)
      pendingMsgRef.current = null
    }
  }, [userData, sendMessage])

  const toggleVisibility = useCallback(() => {
    dispatch(chatReducer.actions.toggleVisibility())
  }, [dispatch])

  const chatMessageClick = useCallback(
    (msg: Message) => {
      dispatch(chatReducer.actions.intent_ChatMessageClick(msg))
    },
    [dispatch]
  )

  const chatUnpinnedMessage = useCallback(
    (msg: Message[]) => {
      dispatch(chatReducer.actions.intent_UnpinnedMessage(msg))
    },
    [dispatch]
  )

  const askForInformation = useCallback(
    (product: Product) => {
      dispatch(chatReducer.actions.intent_AskForInformation(product))
    },
    [dispatch]
  )

  const [liveCount] = useObjectVal<number>(
    firebase?.database().ref('liveCount')
  )

  const contextValue = useMemo(() => {
    return {
      visible: visible,
      toggleVisibility: toggleVisibility,
      sendMessage: sendMessageIntent,
      setUser: setUserData,
      enabled: true,
      type: config.type,
      messages: messages || [],
      pinnedMessage: pinnedMessages || ({} as Message),
      chatMessageClick,
      chatUnpinnedMessage,
      like,
      likesDiff$,
      isSendingMessage,
      isSendingLike,
      askForInformation,
      liveCount: liveCount || 0,
    }
  }, [
    isSendingMessage,
    config,
    messages,
    pinnedMessages,
    sendMessageIntent,
    setUserData,
    toggleVisibility,
    visible,
    chatMessageClick,
    chatUnpinnedMessage,
    like,
    likesDiff$,
    isSendingLike,
    askForInformation,
    liveCount,
  ])

  return (
    <ChatProviderContext.Provider value={contextValue}>
      {formModal.elem}
      {children}
    </ChatProviderContext.Provider>
  )
}

/**
 * Remove duplicates and normalize cache + new messages
 */
export function normalizeMessageCache<T extends { id: MimoId }>(
  cache: T[],
  newList: T[]
) {
  const map = new Map<MimoId, boolean>()

  return [...cache, ...newList]
    .reduceRight<T[]>((acc, next) => {
      if (!map.has(next.id)) {
        map.set(next.id, true)
        acc.push(next)
      }
      return acc
    }, [])
    .reverse()
}

export function normalizePinnedMessage(list: any, messages: Message[]) {
  const _list: Message[] = (list || [])?.map((l: any, b: any) => {
    return { ...l.val(), id: l.key } as Message
  }, {})

  const pinnedMessage: Message[] = _list
  return pinnedMessage
}

/**
 * Extracts the like feature into a function
 */
function useLike(firebase: any, config: ChatFirebaseConfig) {
  const [isSendingLike, setIsSendingLike] = useState(false)

  const [likeCount, _, error] = useObjectVal<number>(
    firebase?.database().ref('likeCount')
  )

  const params = useEmbedParams()

  if (error) {
    console.error(error)
    if (params.debug) {
      throw error
    }
  }

  const { id: liveId } = useConfig()

  const [like, likes$] = useObservableCallback(($input) => {
    return $input.pipe(
      throttleTime(500),
      map(() => {
        setIsSendingLike(true)
        return jsonFetch(
          generateURL(config.meta.endpoints.sendLike, {
            MIMO_LIVE_ID: liveId!,
            MIMO_API_VERSION: API_VERSION,
          }),
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              liveId: liveId,
            }),
          }
        )
          .pipe(retryWhen(configRetryStrategy))
          .pipe(
            tap(() => {
              setIsSendingLike(false)
            })
          )
      }),
      mergeAll()
    )
  })
  useSubscription(likes$)

  const lastLikeCount = useRef<number | null>(null)
  const lastLikeTime = useRef<number>(new Date().valueOf())
  const [likeDiff, likesDiff$] = useObservableCallback<number>(
    (likes$) => likes$
  )

  useEffect(() => {
    const time = Math.ceil(new Date().valueOf() / 8000)
    if (!likeCount || lastLikeTime.current == time) {
      return
    }
    lastLikeTime.current = time

    if (lastLikeCount.current === null) {
      lastLikeCount.current = likeCount
      return
    }

    let diff = likeCount - lastLikeCount.current

    if (diff > 1) {
      const minLikes = Math.ceil(diff / 10)
      diff = minLikes >= 4 ? 4 : minLikes
    }

    lastLikeCount.current = likeCount

    diff > 0 && likeDiff(diff)
  }, [likeCount, likeDiff])

  // cast as a tuple
  return [like, likesDiff$, isSendingLike] as [
    (args: unknown) => void,
    Observable<number>,
    boolean
  ]
}
