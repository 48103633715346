import sentryInit from 'services/sentry';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { onEnvironment } from 'shared/environment';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './stores/store';
import 'material-symbols';
import 'front-commons/entities';
import 'front-commons/constructors'
import 'front-commons/prototypes';
import 'shared/gtm/entity';
import 'stores/entity';
import 'front-commons/entities'

sentryInit();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

async function defferRender() {
	if (onEnvironment('production')) return;

	const { worker } = await import('./mocks/browser');

	if (JSON.parse(import.meta.env.VITE_INTERCEPT_REQUESTS || 'false')) worker.start();
}

defferRender().finally(() => {
	root.render(
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>,
	);
});

reportWebVitals();
