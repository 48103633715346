import features from 'config/featureFlags';
import { pageGenerator } from './helpers';
import { PageSchema } from './interfaces';

export const pages: PageSchema = {
	generic: [
		{
			pageDirPath: '/Faq',
			title: 'Ficou com alguma dúvida?',
			pathname: '/perguntas-frequentes',
			headerType: 'logged_and_not_logged',
			protectType: 'public',
		},
		{
			pageDirPath: '/AboutUs',
			title: 'Sobre nós',
			pathname: '/sobre-nos',
			protectType: 'public',
			headerType: 'logged_and_not_logged',
		},
		{
			pageDirPath: '/Fidelity',
			title: 'Fidelidade',
			pathname: '/fidelidade',
			headerType: undefined,
			protectType: 'public',
			showFooter: false,
		},
		{
			pageDirPath: '/Partnerships',
			title: 'Parcerias',
			pathname: '/parcerias',
			headerType: 'home',
			protectType: 'by_context',
			needContexts: ['pharmacy'],
		},
		{
			pageDirPath: '/LandingPage',
			title: 'Landing Page',
			pathname: '/lp/:campaign',
			headerType: undefined,
			protectType: 'public',
		},
	],
	pos: [
		{
			pageDirPath: '/pos/Distributors',
			title: 'Distribuidores - Minhas farmácias',
			pathname: '/minhas-farmacias/distribuidores',
			protectType: 'private',
			headerType: 'logged',
		},
		{
			pageDirPath: '/pos/FirstAccess',
			title: 'Primeiro Acesso',
			pathname: '/primeiro-acesso',
			protectType: 'private',
			headerType: 'logged',
		},
		{
			pageDirPath: '/pos/Information',
			title: 'Dados da farmácia - Minhas farmácias',
			pathname: '/minhas-farmacias/dados',
			protectType: 'private',
			headerType: 'logged',
		},
		{
			pageDirPath: '/pos/List',
			title: 'Minhas farmácias',
			pathname: '/minhas-farmacias',
			protectType: 'private',
			headerType: 'logged',
		},
		{
			pageDirPath: '/pos/ManageAccess',
			title: 'Acessos - Minhas farmácias',
			pathname: '/minhas-farmacias/acessos',
			protectType: 'private',
			headerType: 'logged',
		},
		{
			pageDirPath: '/pos/Orders/Details',
			title: 'Detalhes do pedido - Meus pedidos - Minhas farmácias',
			pathname: '/minhas-farmacias/pedidos/:order',
			protectType: 'private',
			headerType: 'home',
		},
		{
			pageDirPath: '/pos/Orders/List',
			title: 'Meus pedidos - Minhas farmácias',
			pathname: '/minhas-farmacias/pedidos',
			headerType: 'home',
			protectType: 'by_context',
			needContexts: ['pharmacy'],
		},
		{
			pageDirPath: '/pos/Register/PosData',
			title: 'Cadastrar farmácia - Minhas farmácias',
			pathname: '/minhas-farmacias/cadastro',
			enabled: features.POS_REGISTER,
			protectType: 'private',
			headerType: 'logged',
		},
		{
			pageDirPath: '/pos/Register/QuizValidation',
			title: 'Validação - Cadastrar farmácia - Minhas farmácias',
			pathname: '/minhas-farmacias/cadastro/validacao',
			protectType: 'private',
			headerType: 'logged',
		},
		{
			pageDirPath: '/pos/RequestLink',
			title: 'Solicitar acesso - Minhas farmácias',
			pathname: '/minhas-farmacias/vincular-farmacia',
			enabled: features.POS_REGISTER,
			protectType: 'private',
			headerType: 'logged',
		},
		{
			pageDirPath: '/pos/Simulate',
			title: 'Simular farmácia',
			pathname: '/simular-farmacia',
			protectType: 'private',
			headerType: 'logged',
		},
	],
	user: [
		{
			pageDirPath: '/user/PhoneSubmit',
			title: 'Cadastro de celular',
			pathname: '/cadastro/celular',
			protectType: 'private',
			headerType: 'hide',
		},
		{
			pageDirPath: '/user/Information',
			title: 'Meus dados - Meu perfil',
			subtitle: 'Aqui você pode ver e alterar seus dados, seu e-mail e sua senha.',
			pathname: '/meu-perfil/dados',
			protectType: 'private',
			headerType: 'logged',
		},
		{
			pageDirPath: '/user/UpdateEmail',
			title: 'Atualizar email - Meus dados - Meu perfil',
			subtitle: 'Preencha os dados abaixo para realizar a alteração de e-mail',
			pathname: '/meu-perfil/dados/alterar-email',
			featureFlag: 'CUSTOMER_DATA_EMAIL_CHANGE',
			protectType: 'private',
			headerType: 'logged',
		},
		{
			pageDirPath: '/user/UpdateEmailConfirmation',
			title: 'Confirmação - Atualizar email - Meus dados - Meu perfil',
			subtitle: 'Insira o código de 6 dígitos que foi enviado via e-mail.',
			pathname: '/meu-perfil/dados/alterar-email/confirmacao',
			featureFlag: 'CUSTOMER_DATA_EMAIL_CHANGE',
			protectType: 'private',
			headerType: 'logged',
		},
		{
			pageDirPath: '/user/UpdatePassword',
			title: 'Atualizar senha - Meus dados - Meu perfil',
			subtitle: 'Insira o código de 6 dígitos que foi enviado via e-mail.',
			pathname: '/meu-perfil/dados/alterar-senha',
			protectType: 'public',
			headerType: 'logged_and_not_logged',
		},
	],
	auth: [
		{
			pageDirPath: '/auth/TwoFactorAuthentication',
			title: 'Verifique a sua identidade',
			pathname: '/cadastro/celular/validacao',
			protectType: 'private',
			headerType: 'hide',
		},
		{
			pageDirPath: '/auth/TwoFactorAuthentication',
			title: 'Verifique a sua identidade',
			pathname: '/usuario/validacao-de-token',
			protectType: 'private',
			headerType: 'hide',
		},
		{
			pageDirPath: '/auth/Register',
			title: 'Cadastro',
			pathname: '/cadastro',
			protectType: 'not_logged',
			headerType: undefined,
		},
		{
			pageDirPath: '/auth/Register/Validation',
			title: 'Validação - Cadastro',
			pathname: '/cadastro/validacao',
			headerType: undefined,
		},
		{
			pageDirPath: '/auth/ResetPassword',
			title: 'Recuperar senha - Login',
			subtitle: 'Insira o endereço de e-mail associado à sua conta Parceiro Hypera',
			pathname: '/redefinir-senha',
			protectType: 'not_logged',
			headerType: undefined,
		},
		{
			pageDirPath: '/auth/Login',
			title: 'Login',
			pathname: '/login',
			protectType: 'not_logged',
			headerType: undefined,
		},
		{
			pageDirPath: '/auth/Login',
			title: 'Login',
			pathname: '/rv',
			protectType: 'not_logged',
			headerType: undefined,
		},
	],
	policy: [
		{
			pageDirPath: '/policy/Reduced',
			title: 'Normas de Segurança e Privacidade',
			pathname: '/politicas-de-privacidade',
			protectType: 'public',
			headerType: 'logged_and_not_logged',
		},
		{
			pageDirPath: '/policy/Complete',
			title: 'Normas de Segurança e Privacidade',
			pathname: '/politicas-de-privacidade/completo',
			protectType: 'public',
			headerType: 'logged_and_not_logged',
		},
	],
	checkout: [
		{
			pageDirPath: '/checkout/OrderRevision',
			title: 'Revisar pedido',
			pathname: '/revisar-pedido',
			headerType: 'home',
			protectType: 'by_context',
			needContexts: ['pharmacy'],
		},
		{
			pageDirPath: '/checkout/ProductsUnavailable',
			title: 'Revisar produtos indisponíveis',
			pathname: '/revisar-pedido/produtos-indisponiveis',
			headerType: 'home',
			protectType: 'by_context',
			needContexts: ['pharmacy'],
		},
		{
			pageDirPath: '/checkout/OrderFinalized',
			title: 'Pedido confirmado',
			pathname: '/pedido-finalizado/:posId/:closedBasketGroupId',
			headerType: 'home',
			protectType: 'by_context',
			needContexts: ['pharmacy'],
		},
		{
			pageDirPath: '/checkout/OrderOptimization',
			title: 'Otimização de pedido',
			pathname: '/otimizacao-de-pedido',
			headerType: 'home',
			protectType: 'by_context',
			needContexts: ['pharmacy'],
		},
	],
	products: [
		{
			pageDirPath: '/products/ProductsSearch',
			title: 'Busca de produtos',
			pathname: '/busca/:search',
			headerType: 'home',
			protectType: 'by_context',
			needContexts: ['pharmacy'],
		},
		{
			pageDirPath: '/products/Assortment',
			title: 'Acelera sortimento',
			pathname: '/acelera-sortimento',
			headerType: 'home',
			protectType: 'by_context',
			needContexts: ['pharmacy'],
		},
		{
			pageDirPath: '/products/ProductCategory',
			title: 'Visualizar categoria',
			pathname: '/categorias/:category',
			headerType: 'home',
			protectType: 'by_context',
			needContexts: ['pharmacy'],
		},
		{
			pageDirPath: '/products/Promotions',
			title: 'Promoções',
			pathname: '/promocoes',
			headerType: 'home',
			protectType: 'by_context',
			needContexts: ['pharmacy'],
		},
		{
			pageDirPath: '/products/Releases',
			title: 'Lançamentos',
			pathname: '/lancamentos',
			headerType: 'home',
			protectType: 'by_context',
			needContexts: ['pharmacy'],
		},
		{
			pageDirPath: '/products/Portfolio',
			title: 'Portfólio',
			pathname: '/produtos',
			headerType: 'home',
			protectType: 'by_context',
			needContexts: ['pharmacy'],
		},
		{
			pageDirPath: '/products/Home',
			title: 'Bem-vindo ao Parceiro Hypera',
			pathname: '/',
			headerType: 'home',
			protectType: 'by_context',
			needContexts: ['pharmacy'],
		},
		{
			pageDirPath: '/products/Suggestions',
			title: 'Sugestões de pedidos',
			pathname: '/sugestoes-de-pedidos',
			headerType: 'home',
			protectType: 'by_context',
			needContexts: ['pharmacy'],
		},
		{
			pageDirPath: '/products/OrderImport',
			title: 'Importação de pedido',
			pathname: '/importacao-pedido',
			headerType: 'logged',
			protectType: 'private',
		},
		{
			pageDirPath: '/products/OrderImport/UploadExceptions',
			title: 'Resultado da importação',
			pathname: '/importacao-pedido/resultado/:importId',
			headerType: 'logged',
		},
		{
			pageDirPath: '/products/OrderImport/Customization',
			title: 'Customização de pedido',
			pathname: '/importacao-pedido/customizacao-de-pedido/:importId',
			headerType: 'logged',
			protectType: 'external_provider',
		},
		{
			pageDirPath: '/products/OrderImport/CopyAndPaste',
			title: 'Copie e cole',
			pathname: '/importacao-pedido/copia-e-cola',
			headerType: 'logged',
			protectType: 'private',
		},
		{
			pageDirPath: '/products/OrderImport/FinishOrder',
			title: 'Finalizar pedido',
			pathname: '/importacao-pedido/finalizar-pedido/:importId',
			headerType: 'logged',
			protectType: 'external_provider',
		},
		{
			pageDirPath: '/products/OrderImport/PurchaseConfirmation',
			title: 'Confirmação de compra',
			pathname: '/importacao-pedido/confirmacao-de-compra',
			headerType: 'logged',
			protectType: 'external_provider',
		},
		{
			pageDirPath: '/products/OrderImport/MissingDistributor',
			title: 'Seleção de distribuidores',
			pathname: '/importacao-pedido/selecao-de-distribuidores/:orderImportId',
			headerType: 'logged',
			protectType: 'private',
		},
		{
			pageDirPath: '/checkout/OrderOptimization',
			title: 'Otimização de pedido',
			pathname: '/importacao-pedido/otimizacao',
			headerType: 'logged',
			protectType: 'external_provider',
		},
	],
	reports: [
		{
			pageDirPath: '/reports/Download',
			title: 'Busca de produtos',
			pathname: '/relatorios/:reportId/download',
			headerType: 'logged',
			protectType: 'private',
		},
	]
};

export default pageGenerator(pages);
