import { FilterProps } from "./interfaces";

export function groupContactsByFirstLetter(array: GenericLabelValue[]) {
	const result: { [letter: string]: GenericLabelValue[] } = {};

	array.forEach((i) => {
		const firstLetter = i.label.charAt(0).toUpperCase();

		if (result[firstLetter]) {
			result[firstLetter].push(i);
		} else {
			result[firstLetter] = [i];
		}
	});

	return result;
}

export const getPriceComparisonState = () => window.history.state?.priceComparison || false;

export const getValuesFromUrl = (filters?: FilterProps[], searchParams?: URLSearchParams) => {
	const values: { name: string; values: string[] }[] = [];

	filters?.forEach((filter: any) => {
		const filterValues = searchParams?.get(filter.name);

		if (filter.name === "lancamentos" && filterValues === 's') {
			values.push({
				name: filter.name,
				values: ['Ver lançamentos'],
			});
		} else if (filterValues) {
			values.push({
				name: filter.name,
				values: filterValues.split(','),
			});
		}
	});

	return values;
}

export const filterTypes = ['categorias', 'marcas', 'mecanicas', 'divisoes', 'vigencia', 'lancamentos', 'promocoes'];